<template>
  <main>
    <div class="container">
      <h1>Контакты</h1>
      <hr>
      <h3>Возникли вопросы?</h3>
      <p>Свяжитесь с нами в рабочие дни <b>с 10:00 до 23:00</b></p>
      <p>
        Служба заботы о клиентах: <b><a href="tel:+77273108234"><nobr>+7 727 310-82-34</nobr></a></b><br>
        Email: <b><a href="mailto:support@gifterycard.kz">support@gifterycard.kz</a></b>
      </p>
    </div>
  </main>
</template>

<script>
export default {
  name: 'Contacts',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--inner')
  }
}
</script>
